import { useContext } from "react";

import {
  Box,
  Link,
  Text,
  system,
  SystemProps,
  RoundedButton,
  Flex,
} from "flicket-ui";
import styled from "styled-components";

import { BaseContext } from "~context";
import { Icon } from "~components/common/Icon";
import { Dropdown } from "~components/common/Menu/menu.Dropdown";
import { useIsMounted } from "~hooks/useIsMounted";
import { FixedSizeImage } from "../Image";
import HeaderAlertBanner from "../common.HeaderAlertBanner";

interface MenuProps extends SystemProps {
  pathname: string;
  firstName?: string;
  lastName?: string;
  loggedIn?: boolean;
  checkingSession?: boolean;
}

const StyledMenu = styled(Box)<SystemProps>`
  width: 100%;

  background: ${(p) => p.theme.colors.white};

  font-size: ${(p) => p.theme.fontSizes[3]};
  letter-spacing: -0.165px;

  box-shadow: ${(p) => p.theme.shadows.sm};

  ${system}
`;

const StyledBox = styled(Box)`
  position: relative;

  display: flex;
  align-items: center;

  height: 40px;

  &::after {
    content: "";

    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    width: 1px;
    height: 100%;

    background: ${(p) => p.theme.colors.N200};
  }
`;

const NavLink = styled(Link)<SystemProps & { current?: boolean }>`
  color: ${(p) => p.theme.colors.N500};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.demiBold};
  line-height: 18px;
  letter-spacing: -0.5px;

  ${(p) =>
    p.current &&
    `
    color: ${p.theme.colors.N800};
  `}
`;

export const DesktopMenu = ({
  firstName,
  lastName,
  loggedIn,
  checkingSession,
  pathname = "/",
  ...props
}: MenuProps) => {
  const isMounted = useIsMounted();
  const { organization, isSinglePage } = useContext(BaseContext);

  return (
    <StyledMenu {...props}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        minHeight="72px"
        px={4}
      >
        <Link
          to={
            organization?.slug === "artfair" ? "http://www.artfair.co.nz" : "/"
          }
        >
          <FixedSizeImage
            image={organization.branding?.logo}
            fallback="/static/flicket-white.png"
            alt={`${organization.name} logo`}
            sizes={"(min-width: 200px) 200px"}
            priority={true}
            width={200}
            height={40}
          />
        </Link>
        <Box d="flex" alignItems="center" color="N500">
          {!isSinglePage ? (
            <StyledBox>
              <NavLink
                to="/"
                current={pathname === "/"}
                mr={!organization?.showMemberships ? 3 : 0}
                mx={3}
              >
                Events
              </NavLink>
              {organization?.showMemberships ? (
                <NavLink
                  ml={organization?.shopify ? 3 : 0}
                  mx={organization?.shopify ? 0 : 3}
                  to="/memberships"
                  current={pathname === "/memberships"}
                >
                  Memberships
                </NavLink>
              ) : null}
              {organization?.shopify && (
                <NavLink
                  mx={3}
                  to="/merchandise"
                  current={pathname === "/merchandise"}
                >
                  Merchandise
                </NavLink>
              )}
            </StyledBox>
          ) : (
            <>
              {organization?.shopify && (
                <NavLink
                  mx={3}
                  to="/merchandise"
                  current={pathname === "/merchandise"}
                >
                  Merchandise
                </NavLink>
              )}
            </>
          )}
          <Box>
            {loggedIn ? (
              <>
                <Dropdown
                  organization={organization}
                  ml={3}
                  firstName={firstName}
                  lastName={lastName}
                />
              </>
            ) : (
              <>
                <NavLink
                  mr={3}
                  ml={3}
                  to="/signup"
                  current={pathname === "/signup"}
                >
                  Sign up
                </NavLink>
                <NavLink to="/login" current={pathname === "/login"}>
                  Log in
                </NavLink>
              </>
            )}
          </Box>
          {!isSinglePage && !!organization?.showMemberships && (
            <RoundedButton
              primary
              ml={3}
              to={
                loggedIn ? "/members-area" : "/login?redirect=%2Fmembers-area"
              }
              boxShadow={
                "0px 9px 6px rgba(0, 0, 0, 0.05), 0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0774939), 0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0525061)" as any
              }
            >
              <Icon fontSize={5} mr="3/4" icon="membership" color="white" />
              <Text variant="heavy.L" lineHeight="medium" color="white">
                Members Area
              </Text>
            </RoundedButton>
          )}
        </Box>
      </Flex>

      <HeaderAlertBanner />
    </StyledMenu>
  );
};
