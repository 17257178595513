import { log } from "~features/seated-reservation/helpers/stateLogger";
import { setState, getState } from "../non-seated-reservation-store";
import {
  TTrackingContext,
  trackAddToCart,
  trackRemoveFromCart,
  trackSelectItem,
} from "~lib/helpers/googleAnalyticsTracking";
import { IReleaseTicket } from "~features/nonseated-reservation/nonseated-reservation.types";

type Payload = {
  id: string;
  ticketType: IReleaseTicket;
  quantity: number;
  zoneId: string;
  checkDepsFunction: (itemsToAdd: IReleaseTicket[]) => IReleaseTicket[];
};

export function updateSelectedTicketTypes(payload: Payload) {
  log("updateSelectedTicketTypes", payload);

  const state = getState();

  const trackingData: TTrackingContext = {
    ...state.trackingContext,
    ticketType: payload.ticketType,
  };

  trackSelectItem(trackingData);

  const ticketTypes = state.selectedTicketTypes[payload.id] || [];

  let updatedTicketTypes: IReleaseTicket[] = [];

  const existingTicketType = ticketTypes.find(
    (tt) =>
      tt.id === payload.ticketType.id && tt.zoneId === payload.ticketType.zoneId
  );

  if (existingTicketType === undefined && payload.quantity > 0) {
    // Add ticket type for the first time
    updatedTicketTypes = [
      ...ticketTypes,
      {
        ...payload.ticketType,
        quantity: payload.quantity,
      },
    ];

    trackAddToCart({
      ...trackingData,
      ticketType: updatedTicketTypes[0],
    });
  } else if (existingTicketType && !payload.quantity) {
    // Remove ticket type from selection
    updatedTicketTypes = ticketTypes.filter(
      (tt) =>
        !(
          tt.id === payload.ticketType.id &&
          tt.zoneId === payload.ticketType.zoneId
        )
    );

    trackRemoveFromCart(trackingData);
  } else {
    // Update quantity of existing ticket type
    updatedTicketTypes = ticketTypes.map((tt) => {
      if (
        tt.id === payload.ticketType.id &&
        tt.zoneId === payload.ticketType.zoneId
      ) {
        return {
          ...tt,
          quantity: payload.quantity,
        };
      }

      return tt;
    });

    const ticketForTracking = updatedTicketTypes.find(
      (tt) =>
        tt.id === payload.ticketType.id &&
        tt.zoneId === payload.ticketType.zoneId
    );

    trackAddToCart({
      ...trackingData,
      ticketType: ticketForTracking,
    });
  }

  setState({
    selectedTicketTypes: {
      ...state.selectedTicketTypes,
      [payload.id]: payload.checkDepsFunction(updatedTicketTypes),
    },
  });
}
