import {
  OrderQuery,
  FeeType,
  OrderChannel,
  IntegrationType,
} from "~graphql/sdk";
import { getPaymentCount } from "./getPaymentCount";
import { getTransactionFee as getTransactionFee2 } from "./getTransactionFee";

export type OrderGateway =
  | OrderQuery["order"]["membership"]["gateways"][number]
  | OrderQuery["order"]["event"]["gateways"][number];

export type Method = OrderGateway["id"] | "point";
interface GetTransactionFeeAmountProps {
  step: number;
  total: number;
  gateway: OrderGateway;
  paymentCount: number;
}

export type TransactionFee = {
  transactionFeeAmount: number | null;
  transactionFeeType?: FeeType;
  transactionFee?: number;
  transactionFeeType2?: FeeType;
  transactionFee2?: number;
};

const getTransactionFeeAmount = ({
  step,
  total,
  gateway,
  paymentCount,
}: GetTransactionFeeAmountProps): TransactionFee => {
  let transactionFeeAmount = 0;

  if (!gateway) return { transactionFeeAmount };

  if (!gateway.transactionFeeType || gateway.transactionFee === undefined) {
    transactionFeeAmount = null;
  }

  if (step === 3 && gateway) {
    if (
      (gateway.type === IntegrationType.PaymentStripe ||
        gateway.type === IntegrationType.PaymentPin) &&
      gateway.transactionFee2 &&
      gateway.transactionFeeType2
    ) {
      // if transactionFeeType is percentage, then transactionFeeType2 should be flat rate.
      if (gateway.transactionFeeType === FeeType.Percentage) {
        transactionFeeAmount = +(
          (total / 100) * gateway.transactionFee +
          gateway.transactionFee2 * paymentCount
        ).toFixed(2);
      } else if (gateway.transactionFeeType === FeeType.FlatRate) {
        transactionFeeAmount = +(
          (total / 100) * gateway.transactionFee2 +
          gateway.transactionFee * paymentCount
        ).toFixed(2);
      }
    } else {
      if (gateway.transactionFeeType === FeeType.Percentage) {
        transactionFeeAmount = +(
          (total / 100) *
          gateway.transactionFee *
          paymentCount
        ).toFixed(2);
      } else if (gateway.transactionFeeType === FeeType.FlatRate) {
        transactionFeeAmount = gateway.transactionFee * paymentCount;
      }
    }
  }

  // transactionFeeAmount is a derived value
  return {
    transactionFeeAmount,
    transactionFeeType: gateway.transactionFeeType,
    transactionFee: gateway.transactionFee,
    transactionFee2: gateway.transactionFee2,
    transactionFeeType2: gateway.transactionFeeType2,
  };
};

export type GetTotalsReturn = {
  orderTotal: number;
  totalFees: number;
  ticketFees: number;
  usedPoints: number;
  userPoints: number;
  remainingPoints: number;
  usedCredits: number;
  userCredits: number;
  remainingCredits: number;
  totalWithTransactionFee: number;
  remainingPayment: number;
  transactionFee: TransactionFee;
  remainingPaymentWithoutTransactionFee: number;
};

type GetTotalsProps = {
  step: number;
  order: OrderQuery["order"];
  method: Method;
  gateway: OrderGateway;
  gateways:
    | OrderQuery["order"]["membership"]["gateways"]
    | OrderQuery["order"]["event"]["gateways"];
  referralReduction?: number;
  userCredits: number;
  userPoints: number;
  partialPaymentMethod: Method;
};

export const getTotals = ({
  step,
  order,
  method,
  gateway,
  gateways,
  userPoints = 0,
  userCredits = 0,
  partialPaymentMethod,
}: GetTotalsProps): GetTotalsReturn => {
  const selectedGateway = partialPaymentMethod
    ? gateways?.find((gateway) => partialPaymentMethod.startsWith(gateway.id))
    : gateway;

  // Get total of all line items (including ticket fees)
  const lineItemsTotal = Number(
    order?.lineItems?.edges
      ?.reduce((acc, lineItem) => (acc += lineItem?.node?.total ?? 0), 0)
      ?.toFixed(2)
  );
  const totalWithOrderFees =
    lineItemsTotal +
    (order?.deliveryFee ?? 0) +
    (order?.transferFee ?? 0) +
    (order?.customTaxAmount ?? 0) +
    (order?.orderTicketCover?.totalFeeAmount ?? 0) +
    (order?.bookingFeeAmount ?? 0) -
    (order?.changingSeatsCredits ?? 0);

  const transactionFee = getTransactionFeeAmount({
    step,
    total: totalWithOrderFees,
    gateway: selectedGateway
      ? {
          ...selectedGateway,
          transactionFee2: getTransactionFee2(
            method ?? partialPaymentMethod,
            order,
            selectedGateway
          ),
        }
      : null,
    paymentCount: getPaymentCount(method ?? partialPaymentMethod, order),
  });

  const orderTotal = order?.total;

  const totalWithTransactionFee =
    order?.channel === OrderChannel.Pos
      ? orderTotal
      : orderTotal +
        ((transactionFee?.transactionFeeAmount || 0) -
          (order?.transactionFeeAmount ?? 0));

  const ticketFees = order?.lineItems?.edges?.reduce(
    (acc, edge) =>
      acc + edge?.node?.ticketFeeAmount * (edge?.node?.quantity ?? 1),
    0
  );

  const totalFees =
    ticketFees +
    transactionFee?.transactionFeeAmount +
    order?.deliveryFee +
    order?.bookingFeeAmount +
    order?.transferFee;

  const usedCredits =
    userCredits >= totalWithTransactionFee
      ? totalWithTransactionFee
      : userCredits;

  let usedPoints = 0;

  if (method === "point") {
    const orderTotalMinusCredit = orderTotal - usedCredits;
    usedPoints =
      userPoints > orderTotalMinusCredit ? orderTotalMinusCredit : userPoints;
  }

  const remainingPayment = totalWithTransactionFee - usedCredits - usedPoints;
  const remainingPaymentWithoutTransactionFee =
    orderTotal - usedCredits - usedPoints;

  return {
    orderTotal,
    totalFees,
    ticketFees,
    usedPoints,
    usedCredits,
    userCredits,
    userPoints,
    remainingCredits: userCredits - usedCredits,
    remainingPoints: userPoints - usedPoints,
    totalWithTransactionFee,
    transactionFee,
    remainingPayment,
    remainingPaymentWithoutTransactionFee,
  };
};
