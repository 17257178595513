import { theme } from "flicket-ui";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";

type Breakpoint = keyof typeof theme["breakpoints"];

export const useIsMobile = (breakpoint: Breakpoint = "md") => {
  const theme = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () =>
      setIsMobile(
        window.innerWidth <
          Number(theme.breakpoints[breakpoint].replace(/\D/g, ""))
      );

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, [theme.breakpoints]);

  return isMobile;
};
